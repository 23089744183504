import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { useMsal } from '../utility/auth/msalContext';
import { loginRequest } from "./../utility/auth/msalConfig";


import { Hint } from './hint'
import { Button } from './button'

import classes from '../styles/code.module.sass'

function getFiles({ allCode }) {
    return Object.assign(
        {},
        ...allCode.edges.map(({ node }) => ({
            [node.name]: node.code,
        }))
    )
}

function makeTest(template, testFile, solution) {
    // Escape quotation marks in the solution code, for cases where we
    // can only place the solution in regular quotes.
    const solutionEscaped = solution.replace(/"/g, '\\"')
    //console.log("testing", template)
    return template
        .replace(/\${solutionEscaped}/g, solutionEscaped)
        .replace(/\${solution}/g, solution)
        .replace(/\${test}/g, testFile)
}

class CodeBlock extends React.Component {
    state = { Juniper: null, showSolution: false, key: 0}
    completedOnce = false
    

    handleShowSolution() {
        this.setState({ showSolution: true, key: this.state.key + 1  })
        //this.setState({ showSolution: true})
    }

    handleReset() {
        // Using the key as a hack to force component to rerender
        this.props.saveCodeBlockData(this.props.id, undefined)
        this.setState({ showSolution: false, key: this.state.key + 1 })
    }

    updateJuniper() {
        // This type of stuff only really works in class components. I'm not
        // sure why, but I've tried with function components and hooks lots of
        // times and couldn't get it to work. So class component it is.
        if (!this.state.Juniper) {
            // We need a dynamic import here for SSR. Juniper's dependencies
            // include references to the global window object and I haven't
            // managed to fix this using webpack yet. If we imported Juniper
            // at the top level, Gatsby won't build.
            import('./juniper').then(Juniper => {
                this.setState({ Juniper: Juniper.default })
            })
        }
    }

    componentDidMount() {
        this.updateJuniper()
    }

    componentDidUpdate() {
        this.updateJuniper()
    }


    render() {
        const { Juniper, showSolution } = this.state
        const { id, source, solution, test, children, kernelHandler} = this.props

        const sourceId = source || `exc_${id}`
        const solutionId = solution || `solution_${id}`
        const testId = test || `test_${id}`
        const juniperClassNames = {
            cell: classes.cell,
            input: classes.input,
            button: classes.button,
            output: classes.output,
            codeBox: classes.codeBox,
        }
        const hintActions = [
            { text: 'Reset', onClick: () => this.handleReset() },
            //{ text: 'Visa lösning', onClick: () => this.handleShowSolution() }
        ]
        /*if (this.props.codeBlockData[id] !== undefined) {
            if (this.props.codeBlockData[id]['status'] === 'complete') {
                this.completedOnce = true
            }
        }*/

        
        
        /*hintActions.push(
            { text: 'Visa lösning', onClick: () => this.handleShowSolution() },
        )*/

        /*if (this.props.submit && this.completedOnce){
            hintActions.push(
                { text: 'Visa lösning', onClick: () => this.handleShowSolution() },
            )
        }*/

        return (
            <StaticQuery
                query={graphql`
                    {
                        site {
                            siteMetadata {
                                testTemplate
                                juniper {
                                    repo
                                    repoPlatform
                                    branch
                                    kernelType
                                    lang
                                    debug
                                }
                            }
                        }
                        allCode {
                            edges {
                                node {
                                    name
                                    code
                                }
                            }
                        }
                    }
                `}
                render={data => {
                    const { testTemplate } = data.site.siteMetadata
                    const { repo, repoPlatform, branch, kernelType, debug, lang } = data.site.siteMetadata.juniper
                    const { loading, isAuthenticated, login, logout, token } = useMsal();
                    const files = getFiles(data)
                    let sourceFile = files[sourceId]
                    const solutionFile = files[solutionId]
                    const testFile = files[testId]
                    //console.log("render codeblock", sourceId)
                    const savedSource = this.props.loadCodeBlockData(id)
                    
                    if (savedSource !== undefined)
                        sourceFile = savedSource
                    
                    if (solutionFile !== undefined) {
                        hintActions.push(
                            { text: 'Visa lösning', onClick: () => this.handleShowSolution() },
                        )
                    }

                    return (
                        <div className={classes.root} key={this.state.key}>
                            {Juniper && (
                                <Juniper
                                    codeBlockId={id}
                                    updateStatus={this.props.updateStatus}
                                    msgButton={null}
                                    classNames={juniperClassNames}
                                    repo={repo}
                                    repoPlatform={repoPlatform}
                                    branch={branch}
                                    lang={lang}
                                    kernelType={kernelType}
                                    kernelHandler={kernelHandler}
                                    debug={debug}
                                    actions={({ runCode }) => (
                                        <div className={classes.actionContainer}>
                                            {isAuthenticated && token && (
                                                <Button onClick={() => runCode(value =>
                                                                {
                                                                    this.props.saveCodeBlockData(id, value)
                                                                    if (this.props.submit) {
                                                                        this.props.updateData(this.props.codeBlockData)
                                                                    }
                                                                return value
                                                                }
                                                            )
                                                        }
                                                    >
                                                        Kör kod
                                                </Button>
                                            )}

                                            {(!isAuthenticated || !token) && (
                                                <Button variant="primary" onClick={() => login(loginRequest, "loginRedirect")}>Logga in för att köra kod</Button>
                                            )}
                                            {isAuthenticated && token && testFile && this.props.submit && (
                                                <Button
                                                    variant="primary"
                                                    onClick={() =>
                                                        runCode(value =>
                                                            {
                                                                const output = makeTest(testTemplate, testFile, value)
                                                                this.props.saveCodeBlockData(id, value)
                                                            return output
                                                            }
                                                        )
                                                    }
                                                >
                                                    Registrera svar
                                                </Button>
                                            )}
                                        </div>
                                    )}
                                >
                                    {showSolution ? solutionFile : sourceFile}
                                </Juniper>
                            )}
                            <Hint actions={hintActions}>{children}</Hint>
                        </div>
                    )
                }}
            />
        )
    }
}

export default CodeBlock
