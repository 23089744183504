import React, { useState, useCallback, useEffect } from 'react'

import { Button } from '../button'
import classes from '../../styles/short-answer.module.sass'
import AnswerInfo from './answer-info'

import {Input } from '../input'

import { useMsal } from '../../utility/auth/msalContext';
import { loginRequest } from "./../../utility/auth/msalConfig";


const ShortAnswer = ( {validation, right="", wrong="", children = [] , excerciseData,toggleButtonComplete, setExcerciseData, forceComplete, forceUncomplete, updateData, didMount}) => {
    const [answer, setAnswer]       = useState('')
    const [inputText, setInputText] = useState('')

    const { loading, isAuthenticated, login, logout, token } = useMsal();

   // const options = children.filter(child => child !== '\n')


    // Assume short answer only consists of one input
    // useEffect(() => { // kanske behöver användas längre upp?
    //  setCorrectAnswer(props.correct)
    // });

    const isCorrect = useCallback(() => {
        return validation(answer)
    }, [answer, validation]);

    const handleAnswer = useCallback(() => {
        setAnswer(inputText)
        setExcerciseData(inputText)
        //updateData(inputText)
    // Callback only on inputText update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputText]) 

    useEffect(() => {
        if (isCorrect()) {
            forceComplete()
            updateData(inputText, true)
        } else {
            forceUncomplete()
            updateData(inputText, false)
        }
    // Callback only on answer update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answer])

    useEffect(() => {
        setInputText(excerciseData)
    }, [excerciseData])
    
    /*useEffect(() => {
        updateData(inputText)
    
    // Callback only on toggleButtonComplete update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleButtonComplete])*/
    
    useEffect(() => {
        if (didMount) {
            //updateData(inputText)
        }
    // Callback only on toggleButtonComplete update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleButtonComplete])  


    return (
        <>     
            <Input inputText={inputText} onChange={event => setInputText(event.target.value) } className={classes.answerInput}></Input>
            
            {(!isAuthenticated || !token) && (
                <Button variant="primary" onClick={() => login(loginRequest, "loginRedirect")}>Logga in för att svara</Button>
            )}

            {isAuthenticated && token && (
                <Button variant="primary" onClick={handleAnswer}>
                    Registrera svar
                </Button>
            )}

            {
                answer.length > 0 && <AnswerInfo isCorrect={isCorrect} right={right} wrong={wrong}></AnswerInfo>
            }
           
        </>
    )
}
/*
export const Input = ({ children }) => {
    return children
}
*/

export default ShortAnswer
